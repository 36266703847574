
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




































































::v-deep {
  .hero__date {
    font-style: normal;
  }
}

.related {
  @include liquid('padding-top', 20px, 27px);

  border-top: 1px currentColor solid;
}

.related__title {
  margin-bottom: 7.7rem;
  font-size: 1.4rem;
  text-transform: uppercase;
}
